.item--positive .colored {
  color: green;
}

.item--negative .colored {
  color: red;
}

.item--info .colored {
  color: #c1c12a;
}

.cpl_tip {
  margin-left: 5px;
  display: inline-block;
  color: gray;
}

.item--lined {
  border-bottom: 1px solid #ddd;
}

.table {
  td {
    border-top: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

@media (max-width: 640px) {
  .cpl_tip {
    margin-left: 0;
    display: block;
    font-size: 12px;
    white-space: nowrap;
  }
}
