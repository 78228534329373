.container {
  margin: 10px 0;
}

.table_container {
  overflow: auto;
  width: 100%;
  height: 300px;
  max-height: 30vh;
}

@media (max-width: 1023px) {
  .table_container {
    height: auto;
  }
}
