.component-block {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.component-block__header {
  margin: 0 0 20px;
}

.save-btn {
  margin: 20px 0;
}

.village-edit__footer {
  margin-bottom: 70px;
}

.commute-set__header {
  margin: 0;
}
