.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
  box-shadow: none;
}

.btn-selected {
  pointer-events: none;
}

button.hh-btn {
  margin-bottom: 15px;
  display: block;
  width: 100%;
}

button.hh-btn > span {
  margin-left: 4px;
}
