.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4px;
}

.btn:not(.btn-selected) {
  filter: grayscale(100%);
  opacity: 0.6;
}
