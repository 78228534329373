.container {
  margin: 20px 0;
}

.day_picker-container input {
  padding: 6px 12px;
  display: block;
  width: 100%;
  height: 34px;
  font-size: 14px;
  line-height: 1.42;
  color: #555;
  background: white none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.07),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}
