.info_item {
  display: inline-block;
  width: 300px;
  vertical-align: top;
}

.info_item_title {
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

.info_item_description {
  margin: 0;
  padding: 0;
}
