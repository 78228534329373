/* stylelint-disable selector-max-compound-selectors */

ul.pagination > li {
  cursor: pointer;
  user-select: none;

  & > span,
  & > a {
    min-width: 40px;
    line-height: 24px;
  }
}

.pagination_item {
  font-size: 26px;
}

.container {
  display: flex;
  align-items: flex-end;
}

.container .pagination {
  flex: 1;
  justify-content: center;
  margin-bottom: 0;
}

.select {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-flow: wrap column-reverse;
    align-items: center;
  }

  .select {
    width: 100px;
  }

  .container .pagination > li > a,
  .container .pagination > li > span {
    padding: 8px 14px;
    cursor: pointer;
    user-select: none;
  }
}

@media screen and (min-width: 767px) {
  .container .pagination {
    margin-left: -100px;
  }
}
