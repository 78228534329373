.container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.container--hidden,
.call_container--hidden {
  display: none;
}

.outside_container {
  position: absolute;
  inset: 0;
  margin: auto;
  display: block;
  max-width: 970px;
  max-height: 720px;
}

.outside_container--with-history {
  max-height: 900px;
}

.modal {
  position: relative;
  padding: 0 30px 20px;
  width: 100%;
  background: white;
}

.modal_header {
  margin: 3px 0 8px;
  padding: 6px 14px 0 0;
  font-size: 20px;
  line-height: 40px;
  border-bottom: 1px solid #f7f7f7;
}

.modal_content audio {
  margin-bottom: 10px;
  width: 100%;
  outline: none;
}

.close_button {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 5px 10px;
  font-size: 30px;
  background: none;
  border: none;
}

.modal_content {
  flex: 1;
  margin-top: 10px;
  overflow-x: hidden;
}
