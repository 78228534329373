.container {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.heading_title {
  margin: 0 20px 0 0;
  display: inline-block;
  vertical-align: top;
}

.total_count {
  margin: 0 -21px;
  padding: 16px 22px;
  font-size: 14px;
  border: 1px solid #e4e4e4;
}
