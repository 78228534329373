.selector_search_field .btn {
  overflow: hidden;
  width: 100%;
  height: 34px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  border: 1px solid #ced4da;
}

.selector_search_field .btn:hover {
  background-color: #f9fafb;
  border: 1px solid #ced4da;
}

.selector_search_field .btn .caret {
  position: absolute;
  top: 15px;
  right: 5px;
}

.item {
  cursor: pointer;
}

.invalid .btn {
  border-color: #a94442;
}

.item,
.empty {
  margin-bottom: 5px;
  padding: 0 10px;
}

.item--active,
.item:hover {
  background: #ccc;
}

.search {
  position: sticky;
  top: 0;
  padding: 5px 10px;
  background-color: white;
}

.selector_search_field .dropdown-menu {
  padding: 0 0 5px;
  overflow: auto;
  width: 100%;
  max-height: 250px;
}

.dropdown-menu .label {
  margin-bottom: 0;
  padding: 5px 0;
}

.dropdown-menu .label--muted {
  color: #999;
}

.dropdown-menu .label-id {
  font-size: 12px;
  color: grey;
}
