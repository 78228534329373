.buttons {
  min-width: 112px;

  div:not(:first-child) {
    margin-left: 5px;
  }
}

.phone {
  white-space: nowrap;
}
