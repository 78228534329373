.test {
  display: flex;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  font-size: 46px;
}

/* stylelint-disable */
:global {
  .notification {
    white-space: pre-wrap;
  }

  .container .form-control[readonly] {
    background-color: #fafafa;
  }

  @media (min-width: 1400px) {
    .container.container-xlg {
      width: 1340px;
    }
  }

  i.glyphicon {
    pointer-events: none;
  }
}
