.total_count {
  margin: 0 -21px;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #e4e4e4;
}

.total_price {
  margin-left: 28px;
}

td audio {
  width: 230px;
}

.btn_save {
  margin-left: auto;
}

.btn_save i {
  margin-right: 5px;
}

.type_row > th {
  font-size: 12px;
  font-weight: normal;
  border-top: 0;
}

.clipped_cell {
  overflow: hidden;
  width: 33%;
  max-width: 0;
  text-overflow: ellipsis;
}

.play {
  margin-right: 10px;
}

.call_record {
  width: 95px;
}

.table_container table tr {
  cursor: pointer;
}

.icon_cell {
  text-align: center;
}

.icon_cell > div,
.icon_cell > span {
  margin: 0;
}

.table_container table td.icon_cell,
.table_container table td.cpl_cell {
  vertical-align: middle;
}

.cpl_cost {
  padding: 5px;
}

.region_name {
  display: block;
  font-size: 12px;
  color: #797979;
}

.phone--blocked {
  color: red;
}
