.infrastructure {
  padding: 20px 0;
}

.infrastructure-row {
  display: flex;
  align-items: flex-end;
}

button.infrastructure-btn {
  margin: 0 10px 15px 0;
}

.infrastructure-wrapper {
  padding: 0 10px 0 0;
  border: none;
}
