.features-container {
  padding: 5px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.feature-container {
  position: relative;
  margin: 11px 45px 13px 0;
  display: inline-block;
  width: 117px;
  text-align: center;
}

.feature-container:hover {
  cursor: pointer;
}

.preview-inline {
  display: inline-block;
  border: solid 1px #f4f4f4;
  border-radius: 2px;
}

.features-wrapper {
  max-width: calc(100% - 105px);

  .feature-wrapper {
    margin: 0;
    padding: 0;
    border: none;
  }
}

.feature-container .remove-btn {
  position: absolute;
  top: -11px;
  right: 0;
  padding: 1px 7px;
  vertical-align: top;
}

.feature-img {
  width: 92px;
  height: 92px;
  object-fit: contain;
  background-color: white;
}

.feature-description {
  margin-top: 8px;
  max-width: 140px;
  font-size: 15px;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.select-input {
  position: absolute;
  z-index: -1;
  display: none;
  overflow: hidden;
  width: 0;
  height: 0;
  color: black;
  border: 3px dashed #adadad;
  opacity: 0;
}

.label {
  margin: 11px 0 0 13px;
  padding: 1px 5px;
  display: inline-block;
  width: 92px;
  height: 92px;
  font-size: 54px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  color: #333;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  flex: 0 1 92px;
}

.label:hover,
.label:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.danger {
  margin: 7px 0;
  display: block;
  color: #c9302c;
}

.full .label {
  display: none;
}

.full .features-wrapper {
  max-width: none;
}
