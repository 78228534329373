.table {
  overflow-y: auto;
  min-height: 230px;
  max-height: 60vh;
}

.table_title {
  margin: 10px 0;
  display: block;
  font-weight: bold;
}

@media (max-width: 450px) {
  .table td {
    padding: 6px !important; /* stylelint-disable-line declaration-no-important */
  }
}
