.container {
  width: 100%;
}

.input-wrapper {
  margin-bottom: 15px;
}

.heading_title {
  margin: 0 20px 20px 0;
  display: inline-block;
  vertical-align: top;
}

.show-phone-text {
  font-weight: normal;
  line-height: 30px;
  cursor: pointer;
}

.is-premium-text {
  margin-left: -10px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
  line-height: 30px;
  cursor: pointer;
  color: #ff8400;
  background-color: #fff5d4;
}

.save-btn {
  float: right;
}

.top_inline_item {
  display: flex;
  justify-content: flex-end;
}
