.feature-container {
  position: relative;
  margin: 11px 45px 13px 0;
  display: inline-block;
  width: 117px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  .control-block {
    position: absolute;
    top: -11px;
    right: -20px;
  }

  .edit-btn {
    margin-right: 4px;
    padding: 1px 7px;
    vertical-align: top;
  }

  .remove-btn {
    padding: 1px 7px;
    vertical-align: top;
  }
}

.preview-inline {
  display: inline-block;
  border: solid 1px #f4f4f4;
  border-radius: 2px;
}

.feature-img {
  width: 92px;
  height: 92px;
  object-fit: contain;
  background-color: white;
}

.feature-description {
  margin-top: 8px;
  max-width: 140px;
  font-size: 15px;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
