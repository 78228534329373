.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 5px;
  font-weight: bold;
}

.title,
.message {
  font-size: 14px;
  line-height: 20px;
  color: black;
}
