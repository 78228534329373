.container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.heading_title {
  margin: 0 20px 0 0;
  display: inline-block;
  vertical-align: top;
}
