.container {
  margin: 0 0 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: hidden;
}

.title {
  position: relative;
  font-weight: bold;
  flex: 1 0 auto;

  &::after {
    position: absolute;
    bottom: 0;
    width: 9999px;
    content: '';
    border: 1px dotted #d0d0d0;
  }
}
