@keyframes bouncing_loader {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
}

.bouncing_loader {
  display: flex;
  justify-content: center;
}

.bouncing_loader > div {
  margin: 3rem 0.2rem;
  width: 1rem;
  height: 1rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing_loader 0.6s infinite alternate;
}

.bouncing_loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing_loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
