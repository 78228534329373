.total_count {
  margin: 0 -21px;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #e4e4e4;
}

.save-report-btn {
  margin-left: auto;
}

.save-report-btn i {
  margin-right: 5px;
}
