.container {
  width: 100%;
}

.input-wrapper {
  margin-bottom: 15px;
}

.container .save-button {
  margin-bottom: 50px;
}

.container .work_time {
  height: 75px;
}

.component-block {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.component-block__header {
  margin: 0 0 20px;
}
