.container {
  padding: 3px 8px;
  display: inline-block;
  background-color: #ffdcd0;
  border-radius: 4px;
}

.container--mobile {
  margin-top: 5px;
  font-size: 12px;
}

.icon {
  margin-right: 5px;
  display: inline-block;
}

.date,
.email,
.reason {
  font-weight: bold;
}
