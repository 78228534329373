.container {
  position: fixed;
  z-index: 1000;
  inset: 0;
  padding: 0;
  overflow-y: scroll;
  background-color: #f4f4f4;
}

.closed_container {
  margin: 15px 0;
}

.header {
  position: fixed;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.filters {
  padding: 70px 15px;
}

.btn-filter {
  width: 100%;
}

.btn-save {
  position: fixed;
  left: 15px;
  bottom: 10px;
  width: calc(100% - 30px);
}

.close {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
}

/* stylelint-disable */
:global {
  .ct_mobile_filters {
    input.form-control,
    select.form-control,
    input[type='datetime-local'] {
      margin-bottom: 15px;
      height: 40px;
      font-size: 16px;
      line-height: 1.25;
    }

    input[type='datetime-local'] {
      line-height: 40px;
    }
  }
}
