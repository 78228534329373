.total_count {
  margin: 0 -11px;
  padding: 16px 11px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  font-size: 14px;
  border: 1px solid #e4e4e4;
}

.select_type {
  width: 141px;
}

td audio {
  width: 230px;
}

.btn_save {
  margin: 10px 0 0;
  width: 100%;
}

.btn_save i {
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .btn_save {
    display: block;
  }
}

@media screen and (max-width: 375px) {
  table {
    font-size: 12px;
  }
}

@media screen and (max-width: 340px) {
  table {
    font-size: 11px;
  }
}
