.container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.container .modal {
  margin-right: 0;
  margin-left: 0;
}

.modal {
  position: relative;
  padding: 0 5px 20px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  background: white;
}

.modal_header {
  margin: 3px 0 8px;
  padding: 6px 14px 0 0;
  font-size: 20px;
  line-height: 40px;
  border-bottom: 1px solid #f7f7f7;
}

.modal_content {
  flex: 1;
  overflow: hidden auto;
}

.modal_footer {
  padding-top: 10px;
  text-align: right;
}

.close_button {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 5px 10px;
  font-size: 30px;
  background: none;
  border: none;
}

.modal_content audio {
  margin-right: 10px;
  width: calc(100% - 50px);
  vertical-align: middle;
}

button.download_button {
  vertical-align: middle;
}

.outside_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 700px;
  height: 90%;
}

.black_list_btn {
  float: left;
}

.region_name {
  margin-left: 5px;
  display: inline-block;
  color: #797979;
}
