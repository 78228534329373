.custom_selector_field span.caret {
  margin-left: 4px;
}

.list-item {
  padding: 5px 10px;
}

.list-item:hover {
  background-color: #e6e6e6;
}

.search {
  padding: 0 10px 5px;
}

.custom_selector_field .dropdown-btn {
  width: 100%;
  height: 34px;
  background-color: white;
  border: 1px solid #ced4da;
}

.custom_selector_field .dropdown-btn:hover {
  background-color: #f9fafb;
  border: 1px solid #ced4da;
}

.custom_selector_field .dropdown-menu {
  display: block;
  width: 100%;
  min-width: auto;
  cursor: pointer;
}

.label + .icon {
  margin-left: 4px;
}

.icon {
  pointer-events: none;
}

button.uncentered {
  text-align: left;
}

.caret {
  position: absolute;
  top: 15px;
  right: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
