.video {
  padding: 20px 0;
}

.video-row {
  display: flex;
  align-items: flex-end;
}

.video-wrapper {
  padding: 0;
  border: none;
}
