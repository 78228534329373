.with-title {
  position: relative;
  display: inline-block;

  .title {
    position: absolute;
    z-index: 10;
    padding: 4px 8px;
    display: none;
    font-weight: normal;
    white-space: nowrap;
    pointer-events: none;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  &:hover .title {
    display: block;
  }
}

.bottom-right {
  top: calc(100% + 2px);
  left: calc(100% + 2px);
}

.bottom-left {
  top: calc(100% + 2px);
  right: calc(100% + 2px);
}
