.container {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
}

.control {
  margin-right: 20px;
  width: 300px;
}

.control--small {
  width: 150px;
}

.container--mobile {
  margin-bottom: 0;

  .control {
    margin-bottom: 10px;
    width: 100%;
  }
  .control--small label {
    display: block;
  }
}
