.dropdown {
  position: absolute;
  z-index: 1;
  margin-top: 3px;
  padding: 5px;
  width: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 3px 0;
}

.address-input {
  position: relative;
}

.item {
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.item:hover {
  color: white;
  background-color: #337ab7;
}

.address-input .invalid {
  border-color: #a94442;
}

.road-station-alert {
  margin-top: 5px;
}
