.container {
  position: fixed;
  z-index: 1;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.5;
}

.modal {
  margin: 0 auto;
  padding: 20px;
  width: 600px;
  background: white;
  border-radius: 5px;
}

.modal-container {
  z-index: 6;
}

.modal-content {
  overflow-y: auto;
  max-height: 550px;
}

.modal-header {
  margin-bottom: 25px;
}

.infrastructures-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.danger {
  margin: 7px 0;
  display: block;
  color: red;
}

.label--danger {
  color: red;
}

.remove_button {
  float: left;
}

.nonchecked-infrastructures > .infrastructure-container {
  border: solid 1px #f4f4f4;
  border-radius: 2px;
  opacity: 0.5;

  &.checked {
    border: solid 1px;
    border-radius: 2px;
    opacity: 1;
  }
}

.infrastructure-container {
  margin: 0 5px 15px;
  display: inline-block;
  border: solid 1px #f4f4f4;
  border-radius: 2px;
}

.infrastructure-container:hover {
  cursor: pointer;
  border: solid 1px #004085;
}

.infrastructure-img {
  width: 55px;
  height: 55px;
  object-fit: contain;
  background-color: white;
}

.description {
  min-width: 270px;
  min-height: 30px;
}

.label {
  margin: 10px 0;
  display: block;
}

.submit-controls {
  margin-top: 20px;

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.display_type {
  margin-top: 10px;
}
