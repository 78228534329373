.container {
  margin: 20px 0;
}

.filter-btn {
  margin-top: 15px;
}

.second-row-filter {
  margin-bottom: 15px;
}

.container .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
}
