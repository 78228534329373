.checkbox-field {
  position: relative;

  .invalid {
    border-color: #a94442;
  }
}

.label {
  margin-right: 10px;
  margin-bottom: 0;
  vertical-align: middle;
}

.tooltip {
  position: absolute;
  top: calc(100% + 10px);
  padding: 8px;
  display: none;
  visibility: hidden;
  max-width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  opacity: 0;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);

  &::before {
    position: absolute;
    top: -6px;
    left: 45%;
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    background-color: white;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    transform: rotate(45deg);
  }
}

input:focus + .tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
}
