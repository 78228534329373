.container {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.title {
  margin: 0 20px 20px 0;
  font-size: 26px;
}

@media screen and (max-width: 375px) {
  .container {
    margin: 0 -15px;
    border-radius: 0;
  }
}
