.container {
  margin: 20px 0;
  max-width: 850px;
}

.title,
.village_select {
  display: inline-block;
  vertical-align: middle;
}

.title {
  margin: 16px 20px 20px 0;
}

.village_select {
  margin-left: 10px;
  height: 34px;
}

.item--positive .colored {
  color: green;
}

.item--negative .colored {
  color: red;
}

.item--info .colored {
  color: #c1c12a;
}

.cpl_tip {
  margin-left: 5px;
  display: inline-block;
  color: gray;
}
