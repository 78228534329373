.container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.load_button {
  margin-right: 10px;
}

.record_field {
  padding: 0 4px;
  flex: 1;
}

.copy_button {
  margin-left: 10px;
}

.container--mobile {
  margin-top: 20px;

  .record_field {
    margin-left: 0;
  }
}
