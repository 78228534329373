.container {
  padding: 20px 0;
}

.add-btn {
  margin-bottom: 25px;
  display: block;
}

.village-object {
  margin: 0 -15px 25px;
  padding: 15px;
  border: 1px solid #f3f3f3;
}
