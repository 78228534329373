.container {
  padding: 20px 0;
}

.container--invalid {
  background-color: #fff4f4;
  border: 1px solid #ffb5b5;
  border-radius: 2px;
}

.delete-btn {
  margin-top: 25px;
}

.add-btn {
  margin-right: 1rem;
}

.village-roads {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.invalid-message {
  margin: 10px 0;
  font-weight: bold;
  letter-spacing: -0.25px;
  color: red;
}

.commute-type {
  line-height: 34px;
}
