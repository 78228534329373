.container {
  position: fixed;
  z-index: 5;
  inset: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.overlay {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.modal {
  margin: 0 auto;
  padding: 20px;
  width: 600px;
  background: white;
  border-radius: 5px;
}

.container--auto_width {
  .modal {
    margin: 0 10px;
    overflow-y: auto;
    width: auto;
    max-height: 99vh;
  }
}

.modal-container {
  z-index: 6;
}

.modal-content {
  overflow: hidden auto;
  max-height: 550px;
}

.modal-header {
  margin-bottom: 25px;
}

.submit-controls {
  margin-top: 20px;

  button:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 639px) {
  .modal-container {
    width: 100%;
  }

  .modal {
    padding: 10px;
    width: calc(100% - 20px);
  }
}
