.container {
  margin: 0 10px 10px 0;
  padding: 10px 12px 14px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.title {
  margin-bottom: 12px;
  display: block;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (min-width: 640px) {
  .container {
    width: 48%;
  }
}

@media screen and (min-width: 992px) {
  .container {
    width: 32%;
  }
}
