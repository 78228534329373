.dnd {
  display: flex;
  flex-direction: column;
}

.dnd--horizontal {
  overflow-x: scroll;
  flex-direction: row;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    max-width: 8px;
    background-color: #7f7f7f;
    border-radius: 10px;
  }
}

.dnd--dragging_over {
  background: #e6e6e66e;
}

.dnd-item {
  margin-bottom: 8px;
  padding: 16px;
  list-style: none;
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:hover {
    background-color: #e6e6e6;
  }
}

.dnd-item--dragging {
  background: lightgreen;
}
