.container {
  position: relative;

  &::before {
    position: absolute;
    left: 13px;
    bottom: 6px;
    pointer-events: none;
    content: '+7 XXX XXX XXXX';
    color: #999;
    opacity: 1;
  }
}

.container--dirty {
  &::before {
    opacity: 0;
  }
}

.container:not(.container--dirty) .input {
  padding-left: 30px;
}

.container--mobile {
  &::before {
    bottom: 9px;
    font-size: 16px;
  }

  &:not(.container--dirty) .input {
    padding-left: 32px;
  }
}
