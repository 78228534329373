.images-container {
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.image-container {
  position: relative;
  margin: 10px 30px 10px 0;
  display: flex;
  width: auto;
}

.select-input {
  position: absolute;
  z-index: -1;
  display: none;
  overflow: hidden;
  width: 0;
  height: 0;
  color: black;
  border: 3px dashed #adadad;
  opacity: 0;
}

.label {
  margin-bottom: 0;
  padding: 1px 5px;
  display: inline-block;
  width: 150px;
  height: 150px;
  align-self: center;
  font-size: 59px;
  font-weight: normal;
  line-height: 145px;
  text-align: center;
  cursor: pointer;
  color: #333;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.label:hover,
.label:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.preview-inline {
  display: inline-block;
}

.image-container .remove-btn {
  position: absolute;
  top: -15px;
  right: -35px;
  padding: 1px 7px;

  i {
    pointer-events: none;
  }
}

.loading-indicator-wrapper {
  margin-top: 50px;
}

.description {
  margin-top: 15px;
  height: 150px;
}

.description-wrapper {
  margin: 0 0 0 15px;
  width: 100%;
}

.sorting_container {
  width: 100%;
}

.sorting_container--inline {
  flex-flow: wrap;
  flex-direction: row;

  .sorting_item {
    margin-right: 15px;
    padding: 0;
    border: none;
  }
}
