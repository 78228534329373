.container {
  display: inline-block;
  width: 450px;
  cursor: pointer;
}

.container--wide {
  margin: 5px;
  width: 100%;
}

.heading {
  max-height: 60px;
  font-size: 14px;

  img {
    height: 42px;
  }
}

.title {
  margin-left: 10px;
  display: inline-block;
  overflow: hidden;
  width: 80%;
  max-height: 50px;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.panel_title {
  display: inline-block;
  width: calc(100% - 45px);
  vertical-align: middle;
  white-space: nowrap;
}

.body_image {
  margin-right: 10px;
  float: left;
  max-width: 25%;
  max-height: 120px;
  object-fit: contain;
}

.container:not(.container--inactive) {
  .heading {
    background-color: white;
  }
}

.edit_button {
  display: inline-block;
  width: 40px;
  height: 21px;
  font-size: 16px;
  vertical-align: middle;
  color: dimgray;
  background: none;
  border: none;
  outline: none;

  i {
    pointer-events: none;
    opacity: 0.4;
    transition: opacity 0.1s ease-in-out;
  }
}

.container .edit_button:hover i {
  opacity: 1;
}

.display_type {
  margin-right: 5px;
  margin-bottom: 5px;
  font-style: italic;
  text-align: right;
  color: darkgray;
}
