.multiselector-field .dropdown-btn {
  overflow: hidden;
  width: 100%;
  height: 34px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  border: 1px solid #ced4da;
}

.multiselector-field .dropdown-btn:hover {
  background-color: #f9fafb;
  border: 1px solid #ced4da;
}

.multiselector-field .dropdown-btn .caret {
  position: absolute;
  top: 15px;
  right: 5px;
}

.list-item {
  margin-bottom: 5px;
  padding: 0 10px;
}

.search {
  padding: 0 10px 5px;
}

.multiselector-field .dropdown-menu {
  overflow: auto;
  width: 100%;
  max-height: 250px;
}

.dropdown-menu .label {
  margin-bottom: 0;
  padding: 5px 0;
  display: block;
  font-weight: normal;
  cursor: pointer;
}

.multiselector-field.invalid .dropdown-btn {
  border-color: #a94442;
}

.select_all {
  padding: 0 10px;
}

.caret {
  position: absolute;
  top: 15px;
  right: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
